import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import * as styles from './../styles/SuccessCase.module.scss'

interface SuccessCaseProps {
  cases: SuccessCase[]
  isDetail?: boolean
}

const CaseCard: React.FC<{ successCase: SuccessCase }> = ({ successCase }: { successCase: SuccessCase }) => {
  const image = getImage(successCase.casePicture.localFile)
  return (
    <div className={styles.caseCardContainer}>
      <GatsbyImage className={styles.cardPicture} alt={`${successCase.id}`} image={image} alt="background"></GatsbyImage>
      <div className={styles.cardTitle}>{successCase.caseTitle}</div>
      <div className={styles.cardContent}>{successCase.caseContent?.data?.caseContent}</div>
    </div>
  )
}

const SuccessCase: React.FC<SuccessCaseProps> = ({ cases, isDetail = false }) => {
  const colLeft = useRef<HTMLDivElement>(null)
  const colRight = useRef<HTMLDivElement>(null)
  let leftRowCount = 0
  let rightRowCount = 0
  const maxDisplayCount = isDetail ? cases.length : 5
  useLayoutEffect(() => {
    //set the items into the columns
    const run = async () => {
      for (let i = 0; i < maxDisplayCount; i++) {
        const cardNodeContainer = document.createElement('div')
        const currentCard = <CaseCard key={cases[i].id} successCase={cases[i]} />
        ReactDOM.render(currentCard, cardNodeContainer)
        if (leftRowCount <= rightRowCount) {
          colLeft.current?.appendChild(cardNodeContainer)
          leftRowCount += cases[i].caseContent?.data?.caseContent.split('\n').length + 10
        } else {
          colRight.current?.appendChild(cardNodeContainer)
          rightRowCount += cases[i].caseContent?.data?.caseContent.split('\n').length + 10
        }
        //sleep for 100ms
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
    }
    run()
  }, [])

  return (
    <div className={styles.successCaseContainer}>
      <div className={styles.successCaseTitle}>成功案例</div>
      <div className={styles.mobileSuccessCaseTitle}>Success Case</div>
      <div className={styles.casesWaterfullContainer}>
        <div id="col1" className={styles.waterfallCol} ref={colLeft} />
        <div id="col2" className={styles.waterfallCol} ref={colRight} />
      </div>
      {!isDetail && (
        <Link className={styles.moreCases} to="/successcases">
          查看更多
        </Link>
      )}
    </div>
  )
}

export default SuccessCase
