// extracted by mini-css-extract-plugin
export var cardContent = "SuccessCase-module--cardContent--9e706";
export var cardPicture = "SuccessCase-module--cardPicture--d466c";
export var cardTitle = "SuccessCase-module--cardTitle--46fdc";
export var caseCardContainer = "SuccessCase-module--caseCardContainer--3c23e";
export var casesWaterfullContainer = "SuccessCase-module--casesWaterfullContainer--8b1ff";
export var mobileSuccessCaseTitle = "SuccessCase-module--mobileSuccessCaseTitle--77492";
export var moreCases = "SuccessCase-module--moreCases--d04f2";
export var successCaseContainer = "SuccessCase-module--successCaseContainer--94b78";
export var successCaseTitle = "SuccessCase-module--successCaseTitle--43956";
export var waterfallCol = "SuccessCase-module--waterfallCol--581c1";